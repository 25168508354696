<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";
export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
  },
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <!--<footer class="footer">-->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img src="images/logo-skillrank.png" height="24" alt="" />
            </a>
            <p class="mt-4">
              Начните поиск сотрудников с Skillrank - закроет проблему формирования воронки кандидатов под вакансию.
            </p>
            <ul class="list-unstyled social-icon social mb-0 mt-4">
              <li class="list-inline-item">
                <a href="https://www.facebook.com/SkillRankAI/" target="_blank" class="rounded">
                  <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://www.instagram.com/goncharenko788/" target="_blank" class="rounded">
                  <instagram-icon
                    class="fea icon-sm fea-social"
                  ></instagram-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://twitter.com/skillrank1" class="rounded">
                  <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://www.linkedin.com/company/skillrank" class="rounded">
                  <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                </a>
              </li>
            </ul>
          </div>


          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="footer-head">Компания</h5>
            <ul class="list-unstyled footer-list mt-4">

              <li>
                <router-link to="/about" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> О нас
                  </router-link>
              </li>
              <li>
                <router-link to="/services" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  Услуги</router-link>
              </li>
              <li>
                <router-link to="/buy" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  Цены</router-link>
              </li>
              <li>
                <router-link to="/contact" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  Контакты</router-link>
              </li>

            </ul>
          </div>

          <div class="col-lg-5 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="footer-head">Правила</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/terms" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  Пользовательское соглашение
                </router-link>
              </li>
              <li>
                <router-link to="/privacy" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  Политика конфиденциальности</router-link>
              </li>
              <li>
                <router-link to="/license" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  Публичная оферта</router-link>
              </li>
            </ul>
          </div>

        </div>

      </div>
    </section>

    <!--</footer>-->

    <!--<footer class="footer footer-bar">-->
    <section>
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">
                © 2020 - {{ new Date().getFullYear() }} Skillrank
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--</footer>-->

  </div>
</template>
