<script>
/**
 * Navbar component
 */

import store from '@/store/store'

import {
  ShoppingCartIcon,MoonIcon,SunIcon,
} from "vue-feather-icons";


export default {
  components: {
    ShoppingCartIcon,MoonIcon,SunIcon
  },
  data() {
    return {

      tokenIsValid: 'yes',

      darkTheme: true,

      isCondensed: false,
      hover:false,

      numberOfResumesViewed:0,
      days_to_expiration:0,
      numberOfResumesInCart:0,
      total_balance: 0,

      in_sql: {
        user_id: 0,
        anonymous_id:0,
        dark_theme:0,
        isLoggedIn:0,
      },
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },



  mounted: () => {
    /*
    window.onscroll = function () {
      onwindowScroll();
    };
    function onwindowScroll() {
      if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }
      if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }
    */





    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }




  },

  created() {
    //this.$cookies.set('showAccountConfirmAlert',false);
    //store.commit('Update_showAccountConfirmAlert_State',this.$cookies.get('showAccountConfirmAlert'));





    console.log('theme is : ');
    let themeVar = document.getElementById("theme-opt").toString();

    if (themeVar.includes('dark.css')) {
      this.darkTheme = true;
    }

    console.log('inside mounted navbar0');
    let token_str_flag = this.$cookies.get('access_token_skillrank');
    console.log('inside navbar: ' + token_str_flag);
    if (token_str_flag!== null) {
      console.log('token:')
      console.log(store.state.isLoggedIn)
      store.commit('UPDATE_TOKEN', this.$cookies.get("access_token_skillrank"));
      store.commit('UPDATE_USERNAME', this.$cookies.get("username_skillrank"));
      store.commit('UPDATE_USER_ID', this.$cookies.get("id_skillrank"));
      store.commit('Update_showAccountConfirmAlert_State', this.$cookies.get("showAccountConfirmAlert"));
      store.commit('UPDATE_LOGGEDIN_STATE', 1);
      /*if (this.$cookies.get("numberOfResumesViewed")!== null) {
        this.numberOfResumesViewed = this.$cookies.get("numberOfResumesViewed");
      }
      if (this.$cookies.get("expiration_date_milis")!== null) {
        this.expiration_date_milis = this.getDaysToExpiration(this.$cookies.get("expiration_date_milis"));
      }*/

      this.checkTokenValidity();

      this.getResumeViews();
      this.getTotalBalance();
      this.getNumberOfResumes();
      this.getNumberOfVacancies();

    }



    this.$store.dispatch('record_event',
        {'browser_var': this.$browserDetect,'axios_var': this.axios,'root_var':this.$hostname,'cookies_var':$cookies,
          'actionType': 'open_page', 'clickedObjectId': ''});
      window.addEventListener('beforeunload', e => this.beforeunloadHandler(e));
      window.addEventListener('unload', e => this.unloadHandler(e));




    if (this.$cookies.isKey('darkTheme')) {
      console.log('isKey check')
      if ( this.$cookies.get('darkTheme') === 'false') {
        console.log('isKey false')
        document.getElementById("theme-opt").setAttribute("href", "/css/style.css");
        document.getElementById("color-opt").setAttribute("href", "/css/colors/default.css");
      } else  {
        console.log('isKey not false')
      }
    }



/*
    this.in_sql.user_id = store.state.user_id;
    this.in_sql.anonymous_id = store.state.anonymous_id;
    this.in_sql.isLoggedIn = store.state.isLoggedIn;

    this.axios({
      method: 'post',
      url: this.$hostname + '/dark-theme-get?access_token=' + store.state.token,
      data: this.in_sql,
    }).then((res) => {
      if (res.data.dark_theme === 0) {
        this.darkTheme = true;
        document.getElementById("theme-opt").setAttribute("href", "./css/style-dark.css");
        document.getElementById("color-opt").setAttribute("href", "./css/colors/cyan.css");
      } else {
        this.darkTheme = false;
        document.getElementById("theme-opt").setAttribute("href", "./css/style.css");
        document.getElementById("color-opt").setAttribute("href", "./css/colors/default.css");

      };
    });
*/


  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },


  methods: {

    goToPayment() {
      this.$router.push({path:'/buy'});
    },

      setDark() {
        console.log('inside setDark')
        console.log(document.getElementById("theme-opt"));

        document.getElementById("theme-opt").setAttribute("href", "/css/style-dark.css");
        document.getElementById("color-opt").setAttribute("href", "/css/colors/cyan.css");
        this.darkTheme = true;

        this.in_sql.user_id = store.state.user_id;
        this.in_sql.anonymous_id = store.state.anonymous_id;
        this.in_sql.dark_theme = 0;

        this.axios({
          method: 'post',
          url: this.$hostname + '/dark-theme-update?access_token=' + store.state.token,
          data: this.in_sql,
        }).then((res) => {
          this.$cookies.set('darkTheme',true);
        });


      },
      setLight() {
        console.log('inside setLight')
        console.log(document.getElementById("theme-opt"));

        document.getElementById("theme-opt").setAttribute("href", "/css/style.css");
        document.getElementById("color-opt").setAttribute("href", "/css/colors/default.css");
        this.darkTheme = false;


        this.in_sql.user_id = store.state.user_id;
        this.in_sql.anonymous_id = store.state.anonymous_id;
        this.in_sql.dark_theme = 1;

        this.axios({
          method: 'post',
          url: this.$hostname + '/dark-theme-update?access_token=' + store.state.token,
          data: this.in_sql,
        }).then((res) => {
          this.$cookies.set('darkTheme',false);
        });

      },



    getTotalBalance() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/payment/get-account-status?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        console.log('inside getTotalBalance');
        console.log(res.data);
        this.total_balance = res.data.total_balance;
        //this.expiration_date_milis = res.data.expiration_date_milis;
        this.days_to_expiration = this.getDaysToExpiration(res.data.expiration_date_milis);
            store.commit('Update_accountTypeState', res.data.account_type);
      });


    },

    getResumeViews() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/events/get?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        console.log('inside getResumeViews');
        console.log(res.data);
        this.numberOfResumesViewed = res.data.numberOfResumesViewed;
      });

    },


    getNumberOfResumes() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/resume-cart/number-of-resumes-in-cart?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        this.numberOfResumesInCart = res.data.numberOfResumesInCart;
      });


    },





    checkTokenValidity() {


      this.axios({
        method: 'get',
        url: this.$hostname + '/security-check?access_token=' + store.state.token,
      }).then((res) => {
        console.log('inside checkTokenValidity');
        console.log(res.data);
        if (res.data==='success'){
          console.log('token is valid')
          this.tokenIsValid = 'yes';
        } else {
          this.tokenIsValid = 'no';
        }

      }).catch((err) => {
        console.error(err);
        this.tokenIsValid = 'no';
      });


    },




    getDaysToExpiration(exiration_date_milis) {
      console.log('insode getDaysToExpiration')
      var exiration_date = new Date(exiration_date_milis);
      var current_date = new Date();
      var diffInMs = Math.max(exiration_date - current_date,0);
      console.log(diffInMs)
      return Math.round(diffInMs / (1000 * 3600 * 24),0);
    },

    getNumberOfVacancies() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/prod/vacancy/get-count?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        console.log('inside getNumberOfVacancies');
        console.log(res.data);
        store.commit('Update_numberOfVacancies_State', res.data.vacancy_counter);
      });


    },



    beforeunloadHandler(e){
      this.$store.commit('update_session_status', 2);
    },
    unloadHandler(e){
      this.$store.dispatch('record_event',
          {'browser_var': this.$browserDetect,'axios_var': this.axios,'root_var':this.$hostname,'cookies_var':this.$cookies,
            'actionType': 'close_page', 'clickedObjectId': ''});
    },


    logOut() {


        let token = this.$cookies.get("access_token_skillrank")
        this.$cookies.remove("access_token_skillrank");
        this.$cookies.remove("username_skillrank");
        this.$cookies.remove("id_skillrank");
        this.$cookies.remove("authority_skillrank");
        //this.$cookies.remove("skillrank_anonymous_id");

        this.axios({
          method:'delete',
          url: '' + this.$hostname +'/oauth/revoke?access_token='+token,
        }).then( (res) => {

          console.log('revoke')

        }).catch((e) => {
          console.log('error')
        });
        this.$router.push({ path: '/login' });

        this.$store.commit('UPDATE_LOGGEDIN_STATE', 0);

        // console.log('inside logout');

    },



    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },


    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;
      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
  },
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
        id="topnav"
        class="defaultscroll sticky"
        :class="{ 'bg-white': isWhiteNavbar === false }"
    >
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo" to="/" v-if="navLight !== true">
            <img src="../../public/images/logo-skillrank.png" height="24" alt="" />
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img src="../../public/images/logo-skillrank.png" class="l-dark" height="24" alt="" />
            <img
                src="../../public/images/logo-skillrank.png"
                class="l-light"
                height="24"
                alt=""
            />
          </router-link>
        </div>
        <div class="buy-button" v-if="isIcons !== true">
          <div v-if="$store.state.isLoggedIn===0">
          <a
              href="/login"
              class="btn"
              :class="{
              'btn-light': navLight === true,
              'btn-primary': navLight !== true,
            }"
          >Войти</a>
          </div>
          <div v-else class="mt-4 mb-0">
          <div @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }">
            <h6 v-on:click="$router.push('/login')">
              <b>{{ $store.state.username }}</b>
            </h6>
          </div>
            <div class="mt-0">
          <p v-on:click="logOut"
                 @mouseover="hover = true"
                 @mouseleave="hover = false"
                 :class="{ active: hover }" >
            Выйти
          </p>
              </div>
            </div>
        </div>
        <ul class="buy-button list-inline mb-0" v-if="isIcons === true">
          <li class="list-inline-item mb-0 developer-icon">
            <b-dropdown
                right
                variant="link"
                toggle-class="text-decoration-none p-0 pr-2"
                menu-class="dd-menu dropdown-menu-right bg-white shadow rounded border-0 mt-3 py-0"
            >
              <template #button-content>
                <i class="mdi mdi-magnify h4 text-muted"></i>
              </template>
              <div style="width: 300px">
                <form>
                  <input
                      type="text"
                      id="text"
                      name="name"
                      class="form-control border bg-white"
                      placeholder="Search..."
                  />
                </form>
              </div>
            </b-dropdown>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"
            ><i class="mdi mdi-github mdi-18px icons"></i
            ></a>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"
            ><i class="mdi mdi-stack-overflow mdi-18px icons"></i
            ></a>
          </li>
          <li class="list-inline-item mb-0">
            <a
                href="javascript:void(0)"
                class="btn btn-icon btn-soft-primary"
                data-toggle="modal"
                data-target="#productview"
            ><i class="mdi mdi-account-outline mdi-18px icons"></i
            ></a>
          </li>
        </ul>
        <!--end login button-->
        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
                class="navbar-toggle"
                @click="toggleMenu()"
                :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation" v-if="$store.state.isLoggedIn===1">
          <!-- Navigation Menu
          <ul
              class="navigation-menu"
              :class="{ 'nav-light': navLight === true }"
          >
          -->
          <ul class="navigation-menu">
            <li>
              <router-link to="/" class="side-nav-link-ref">Главная</router-link>
            </li>

            <li class="has-submenu mr-5">
            <a href="javascript:void(0)" @click="onMenuClick">Сервисы</a
            ><span class="menu-arrow"></span>
            <ul class="submenu">

              <li>
                <router-link to="/vector-search" class="side-nav-link-ref"
                >Поиск сотрудников</router-link
                >
              </li>
              <li>
                <router-link to ="/job-list" class="side-nav-link-ref">
                  Ваши вакансии
                </router-link>
              </li>
              </ul>
            </li>
            <li class="has-submenu mr-5">
              <a href="javascript:void(0)" @click="onMenuClick">Рассылка</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">

            <li>
            <router-link to="/email" class="side-nav-link-ref">
              Рассылка
            </router-link>
            </li>
            <li>
              <router-link to="/email-templates" class="side-nav-link-ref">
                Шаблоны для рассылок
              </router-link>
            </li>
            <li>
              <router-link to="/email/analytics" class="side-nav-link-ref">
                Аналитика
              </router-link>
            </li>
                </ul>
            </li>

            <li class="has-submenu mr-5">
              <a href="javascript:void(0)" @click="onMenuClick">Корзина</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">
                <li>
                  <router-link to="/cart" class="side-nav-link-ref">
                    Корзина
                  </router-link>
                </li>
                <li>
                  <router-link to="/buy" class="side-nav-link-ref">
                    Подписка
                  </router-link>
                </li>
                <li>
                  <router-link to="/cart-paid" class="side-nav-link-ref">
                    Оплаченные резюме
                  </router-link>
                </li>


                </ul>
            </li>

          <!--  <li class="has-submenu mr-5"> --
            <a href="javascript:void(0)" @click="onMenuClick">Статус</a> -->
            <li>
            <a href="/account">Личный кабинет</a>

           <!-- <span class="menu-arrow"></span>
            <ul class="submenu">
              <li>
                <a>
                  На счете <br>{{  new Intl.NumberFormat().format(total_balance).replace(',', ' ')  }} руб.
                </a>
              </li>

              <li v-if="$store.state.account_type==='demo'">
              <a href="/buy">
                Пробный доступ закончен
              </a>
              </li>

              <li v-if="$store.state.account_type!=='demo'">
                <a href="/buy" v-if="days_to_expiration>=5">
                  Доступ: {{days_to_expiration}} дней
                </a>
                <a href="/buy" v-if="days_to_expiration<5 && days_to_expiration>1">
                  Доступ: {{days_to_expiration}} дня
                </a>
                <a href="/buy" v-if="days_to_expiration===1">
                  Доступ: {{days_to_expiration}} день
                </a>
              </li>







             <!-- <li class="mt-1 ml-5" v-if="$store.state.account_type==='demo'" >
                <span class="text-warning  ">Пробный доступ</span> <br> <span class="text-warning  ">закончен</span>
              </li>
              <li class="has-submenu mr-5" v-else>
                <span>Доступ:</span> {{days_to_expiration}} <span v-if="days_to_expiration>=5">дней</span>
                <span v-if="days_to_expiration>1 & days_to_expiration<5">дня</span>
                <span v-if="days_to_expiration===1">день</span>
                <span v-if="days_to_expiration===0">дней</span>
              </li> --




              </ul> -->

            </li>




            <!--<li class="has-submenu mr-5">
              <a href="javascript:void(0)" @click="onMenuClick">Сервисы</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">

                    <li>
                      <router-link to="/account" class="side-nav-link-ref"
                      >Аккаунт</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/referral" class="side-nav-link-ref"
                      >Реферальная программа</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/jobs" class="side-nav-link-ref"
                      >Вакансии</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/search-candidates" class="side-nav-link-ref"
                      >Поиск резюме</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/email" class="side-nav-link-ref"
                      >Рассылка</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/email-templates" class="side-nav-link-ref"
                      >Шаблоны</router-link
                      >
                    </li>

                <li>
                      <router-link to="/email/analytics" class="side-nav-link-ref"
                      >Аналитика</router-link
                      >
                    </li>

                <li>
                  <router-link to="/cart-paid" class="side-nav-link-ref"
                  >Оплаченные резюме</router-link
                  >
                </li>


              </ul>
            </li> -->

           <!-- <li class="mt-3 ml-2" @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }">
              <sun-icon v-if="darkTheme" class="fea icon-sm text-primary" @click="setLight">
              </sun-icon>
              <moon-icon v-else class="fea icon-sm text-primary" @click="setDark">
              </moon-icon>
            </li> -->

            <!--<li class="mt-1 ml-5">
              <span class="text-muted ">На счете:</span> <br>{{  new Intl.NumberFormat().format(total_balance).replace(',', ' ')  }} руб.
            </li> -->

           <!-- <li class="mt-1 ml-5" v-if="numberOfResumesViewed>10">                
            <span class="text-warning  "> <p> <a href="https://skillrank.ru/account">Пробный доступ закончен, перейдите к оплате </a></p></span>
            <p class="alert alert-info mb-0" role="alert" v-if="numberOfResumesViewed>10">
            <h4 class="alert-heading">Ваш пробный доступ закончен</h4> 
            <p> Чтобы продолжить просматривать контакты соискателей, а также отправлять рассылки, перейдите к оплате </p>
          </div>

            </p> 
            <li class="mt-1 ml-5" v-if="numberOfResumesViewed>10">
            <span class="text-warning  ">Пробный доступ:</span> <br>{{numberOfResumesViewed}} резюме / 10
            
            <div class="alert alert-info mb-0" role="alert">
            <h4 class="alert-heading">Подтвердите почту!</h4>
            <p> Сервис станет доступным после того, как вы подтвердите Вашу почту. Проверьте почтовый ящик, иногда письма могут попадать в спам. </p>
          </div>
             </li> -->
            
           <!-- <li class="mt-1 ml-5" v-if="$store.state.account_type==='demo'" >
            <span class="text-warning  ">Пробный доступ</span> <br> <span class="text-warning  ">закончен</span>
            </li> -->
 
            <!--<li class="mt-1 ml-5" v-else-if="$store.state.account_type==='demo'">
            <span class="text-warning  ">Пробный доступ:</span> <br>{{numberOfResumesViewed}} резюме / 10
            </li> -->
           
            <!--<li class="mt-1" v-else>
               <span class="text-muted  ">Доступ к базе:</span> <br>{{days_to_expiration}} <span v-if="days_to_expiration>=5">дней</span>
              <span v-if="days_to_expiration>1 & days_to_expiration<5">дня</span>
              <span v-if="days_to_expiration===1">день</span>
              <span v-if="days_to_expiration===0">дней</span>
            </li>-->
           <!-- <li class="mt-3 ml-5"
                @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }"
                @click="$router.push('/cart')">
              <shopping-cart-icon class="fea icon-sm text-primary">
                </shopping-cart-icon>
              <span v-if="numberOfResumesInCart>0" class="badge badge-pill badge-danger ">
                {{numberOfResumesInCart}}</span>
              <span v-else class="badge badge-pill badge-light ">
                {{numberOfResumesInCart}}</span>
              <!--<span class="text-muted ">Корзина:</span> <br>{{numberOfResumesInCart}}-->
           <!-- </li> -->


            
            <!--
            <li class="has-submenu">
              <a href="javascript:void(0)" @click="onMenuClick">Кандидатам</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">

                <li>
                  <router-link to="/search-jobs" class="side-nav-link-ref"
                  >Поиск вакансий</router-link
                  >
                </li>
                <li>
                  <router-link to="/send" class="side-nav-link-ref"
                  >Рассылка резюме</router-link
                  >
                </li>
                <li>
                  <router-link to="/index-apps" class="side-nav-link-ref"
                  >Аналитика</router-link
                  >
                </li>



              </ul>
            </li>
            -->

              <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch1"
              />


          </ul>
          <div class="alert alert-info mb-0" role="alert" v-if="$store.state.showAccountConfirmAlert==='yes'">
            <h4 class="alert-heading">Подтвердите почту!</h4>
            <p> Сервис станет доступным после того, как вы подтвердите Вашу почту. Проверьте почтовый ящик, иногда письма могут попадать в спам. </p>
          </div>

          <div class="alert alert-info mb-0" role="alert" v-if="tokenIsValid==='no' && $store.state.showAccountConfirmAlert!=='yes'">
            <h4 class="alert-heading">Необходимо выйти и авторизироваться заново!</h4>
            <p> Для того, чтобы сайт стал активным необходимо выйти и ввести логин и пароль для авторизации заново. </p>  
          </div>
           <!--
          <div class="alert alert-info mb-0" role="alert" v-if="numberOfResumesViewed>10 &  $store.state.account_type==='demo'">
            <h4 class="alert-heading">Ваш пробный доступ закончен</h4>
            <p> Чтобы продолжить просматривать контакты соискателей, а также делать массовые рассылки, перейдите к <a href="https://skillrank.ru/account" class="text-secondary"> <b> оплате </b> </a> </p>
          </div>
            -->
          <div class="alert alert-info mb-0 mt-0" role="alert" v-if=" $store.state.account_type==='demo' && $store.state.showAccountConfirmAlert!=='yes'">
            <h4 class="alert-heading">Ваш пробный доступ закончен</h4>
            <p> Чтобы продолжить просматривать контакты соискателей, а также делать массовые рассылки, перейдите к
              <a @click="goToPayment" @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }"
                 class="text-secondary"> <b> оплате </b> </a> </p>
          </div>

        </div>
        <div  v-if="$store.state.isLoggedIn===0">
          <!-- Navigation Menu-->
          <ul  class="navigation-menu">
        <!--<li class="mt-3 ml-2" @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }">
          <sun-icon v-if="darkTheme" class="fea icon-sm text-primary" @click="setLight">
          </sun-icon>
          <moon-icon v-else class="fea icon-sm text-primary" @click="setDark">
          </moon-icon>
        </li> -->
          </ul>
      </div>
      </div>


    </header>
  </div>
</template>


<style lang="scss">


.active
{
  cursor: pointer;
}

</style>
